import React from 'react'

import { FormDialog, DialogContent, ModelAutocompleteField } from '@front/squirtle'
import { I18n } from '@front/volcanion'
import { Typography } from '@mui/material'

const Popup = ({ onSubmit, current_group_id, has_driver, getFailedNotification }) => (
  <>
    <FormDialog
      name={'transfer_vehicle_popup'}
      formProps={{
        initialValues: {},
        onSubmit,
        getFailedNotification,

      }}
    >
      <DialogContent>
        <ModelAutocompleteField
          name='group'
          model_name='group'
          autocompleteKey={'group_client_id'}
          searchQuery={'startsWith'}
          autocompleteMutator={q => ({
            or: [
              { group_client_id: { startsWith: _.split(q, ' ') } },
              { name: { startsWith: _.split(q, ' ') } }
            ]
          })}
          label={I18n.t('group.label', { count: 1 })}
          _label={{ _text: { fontSize: 16, color: 'primary.600' }, style: { padding: 2 } }}
          labelKeys={['label']}
          searchMode='search'
          loadOnMount
          required
          selectFirst={false}
          config={{
            forced_filter: {
              group_id: { '!': current_group_id }
            },
            mutator: (group) => ({
              ...group,
              label: [group.group_client_id, group.name].join(' - ')
            }),
            watchers: [current_group_id]
          }}
        />
        <Typography sx={{ color: 'red', paddingTop: 2 }}>
          {!!has_driver && I18n.t('vehicle.unpair_warning')}
        </Typography>
      </DialogContent>
    </FormDialog>
  </>
)

export default React.memo(Popup)
