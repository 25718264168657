import { hooks } from '@front/volcanion'

const useApplication = (display_index = 0, options = {}) => {
  const [applications] = hooks.useModelSearch('application', 'get', { initial_filter: {} })
  return _.find(applications, ['display_index', display_index])
}

const useApplicationColor = (application_id, { translated, ...options } = {}) => {
  const [application] = hooks.useModel('application', [application_id], { single: true })
  switch (_.get(application, 'name')) {
    case 'Collecto':
      return 'collectoText'
    case 'TV':
    case 'AP':
    case 'HP':
    default:
      return 'primary.main'
  }
}

export {
  useApplication,
  useApplicationColor
}
