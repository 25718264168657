class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static formToFilterHandler(vehicle) {
    return function formToFilter(values, extra, meta, state) {
      return {
        vehicle
      }
    }
  }
}

export default Callbacks
