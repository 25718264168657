import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'
import { OptionUtils } from '@front/volcanion/utils'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

const Row1 = ({ categories }) =>
  <Grid container spacing={1}>
    {_.map(categories, (category, key) =>
      <Grid item xs key={key}>
        <ModelMenuSelectorField
          label={_.get(category, 'name')}
          name={`options.${category.id}`}
          model_name='vehicleoption'
          loadOnFocus
          searchMode='search'
          allowNone={false}
          multiple
          config={{
            forced_filter: { categoryoption: category?.id },
            validate_filter: useCallback(() => !!category?.id, [category?.id]),
            watchers: [category?.id],
            mutator: elem => ({ label: OptionUtils.getLabel(elem), ...elem })
          }}
        />
      </Grid>)}
  </Grid>

const OptionsSection = ({ categories }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('options.label', { count: 2 })}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
      <Row1 categories={categories} />
    </Box>
  </StepContent>
</>

export default React.memo(OptionsSection)
