class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      const options = _.map(_.groupBy(_.get(record, 'options'), 'categoryoption'), (elem, key) => ({
        [key]: _.map(elem, 'vehicle_option_id')
      }))
      return {
        active: _.get(record, 'active'),
        passenger_capacity: _.get(record, 'passenger_capacity'),
        passenger_luggage_capacity: _.get(record, 'passenger_luggage_capacity'),
        fleet: _.get(record, 'fleet'),
        type: record?.type,
        options: _.assign({}, ...options),
        parent_license: _.get(record, 'parent_license'),
        info: {
          plaque: _.get(record, 'info.plaque'),
          brand: _.get(record, 'info.model.brand'),
          model: _.get(record, 'info.model.vehiclemodel_id'),
          color: _.get(record, 'info.color'),
          comment: _.get(record, 'info.comment'),
          in_service_date: _.get(record, 'info.in_service_date'),
          op_control_date: _.get(record, 'info.op_control_date'),
          insurance_date: _.get(record, 'info.insurance_date'),
          safety_inspection_date: _.get(record, 'info.safety_inspection_date')
        }
      }
    }
  }

  static formToRecordHandler(group) {
    return function formToRecord(values, extra, meta) {
      return {
        group,
        active: _.get(values, 'active'),
        passenger_capacity: _.get(values, 'passenger_capacity'),
        passenger_luggage_capacity: _.get(values, 'passenger_luggage_capacity'),
        fleet: _.get(values, 'fleet'),
        type: values?.type,
        options: _.has(values, 'options') ? _.compact(_.flattenDeep(_.values(_.get(values, 'options')))) : undefined,
        parent_license: _.get(values, 'parent_license'),
        info: _.has(values, 'info') ? {
          plaque: _.get(values, 'info.plaque'),
          model: {
            ...(!_.has(values, 'info.model.name')
              ? { vehiclemodel_id: _.get(values, 'info.model') } : { name: _.get(values, 'info.model.name') }
            ),
            brand: _.get(values, 'info.brand'),
          },
          color: _.get(values, 'info.color'),
          comment: _.get(values, 'info.comment'),
          in_service_date: _.get(values, 'info.in_service_date'),
          op_control_date: _.get(values, 'info.op_control_date'),
          insurance_date: _.get(values, 'info.insurance_date'),
          safety_inspection_date: _.get(values, 'info.safety_inspection_date')
        } : undefined
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        active: true
      }
    }
  }


  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
        force_create: ['info.model']
      })
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'info.plaque')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
