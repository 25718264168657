import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const PACKAGE_ENABLED = hooks.useRelayFeature('PACKAGE_ENABLED')
  const TAXI_ENABLED = hooks.useRelayFeature('TAXI_ENABLED')
  const setFieldValues = hooks.useFormChange()
  const { group_id } = hooks.useParams()
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [brand] = hooks.useFieldValues(['info.brand'])

  const cleanModel = useCallback(v => setFieldValues([{ field: 'info.model', value: undefined }]), [setFieldValues])


  const mergedProps = {
    PACKAGE_ENABLED,
    TAXI_ENABLED,
    isManagingRecord,
    brand,
    group: group_id,
    cleanModel
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
