import { useCallback } from "react"
import { OrangeContact } from ".."
import { hooks } from "@front/volcanion"
function handleOrangeCallHandler() {
  return async function handleOrangeCall(phoneNumber) {
    return OrangeContact.execute('GET', 'clickToCall', { phoneNumber })
  }
}
function handleCallHandler(EXTERNAL_PHONE_SERVICE, orangeCall) {
  return async function call(number) {
    try {
      if (!number) return
      if (EXTERNAL_PHONE_SERVICE === 'aws') {
        const endpoint = connect.Endpoint.byPhoneNumber(number)
        const agent = new connect.Agent()
        const contacts = agent.getContacts()
        if (_.isEmpty(contacts))
          agent.connect(endpoint)
        else if (contacts.length === 1)
          contacts[0].addConnection(endpoint)
      }
      else if (EXTERNAL_PHONE_SERVICE === 'orange') {
        const phoneNumber = encodeURIComponent(number)
        await orangeCall(phoneNumber)
      }
    }
    catch (err) {
      console.warn("Error starting call", err)
    }
  }
}

const usePhoneCall = () => {
  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])
  const handleOrangeCall = useCallback(handleOrangeCallHandler(), [])
  return useCallback(handleCallHandler(EXTERNAL_PHONE_SERVICE, handleOrangeCall), [EXTERNAL_PHONE_SERVICE, handleOrangeCall])
}

export {
  usePhoneCall
}