import React, { useMemo } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {

  const [category_options] = hooks.useModelSearch('categoryoption', 'get', {
    initial_filter: { type: 'vehicle' }
  })

  const categories = useMemo(() => _.map(category_options, (category_option) => ({
    id: _.get(category_option, 'category_option_id'),
    name: _.get(category_option, 'name')
  })), [category_options])

  const mergedProps = {
    categories
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
