import React from 'react'

import { Box } from '@mui/material'
import { TextField } from '@front/squirtle'

const SearchForm = props =>
  <Box hidden>
    <TextField name='test' />
  </Box>

export default React.memo(SearchForm)
