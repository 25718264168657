import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const mergedProps = { isManagingRecord }
  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
